import moment from 'moment';
import authHelper from './helpers/authHelper';
import { axiosErrorHandler, axiosResponseHandler, callTheApServerGet } from './helpers/axiosHelper';
import { hydrateApBasicData } from './helpers/basicHelper';
import { byId, capitalizeFirstLetter, unixTimeStampInSeconds } from './helpers/utility';

authHelper.validate();
hydrateApBasicData();



const pendingRedeemRequestsCountElement = byId('pendingRedeemRequestsCountElement');
const walletBalanceElement = byId('walletBalanceElement');
const totalUsersCountElement = byId('totalUsersCountElement');
const activePlanNameElement = byId('activePlanNameElement');
const activePlanValidityElement = byId('activePlanValidityElement');

const getDashboardDataFun = async () => {
    try {
        const response = await callTheApServerGet(`/basic/getDashboardData`);
        axiosResponseHandler(response);
        const res = response.data;

        if (res.status === 'success') {
            console.log(res);

            pendingRedeemRequestsCountElement.innerHTML = res.redeemRequestsCount;
            walletBalanceElement.innerHTML= res.getDashboardData.depositCredit;
            totalUsersCountElement.innerHTML = res.getDashboardData._count.all_users;

            // setting active plan 

            const  validTill  = res.getDashboardData.owners_plan_details.validTill;
            let  activePlan  = res.getDashboardData.owners_plan_details.activePlan;
            activePlan = activePlan.toLowerCase();
         

            activePlanNameElement.innerHTML=capitalizeFirstLetter(activePlan);

            let validTillText = moment(validTill * 1000).format('DD/MM/YYYY');
            if (unixTimeStampInSeconds() > validTill) {
                validTillText = 'Expired';
            }
            if(activePlan==='free'){
                validTillText = 'Lifetime';
               }
           
          

            activePlanValidityElement.innerHTML = `Validity : ${validTillText}`;
           


        }
    } catch (error) {
        axiosErrorHandler(error);
        console.log(error);
    }

};

getDashboardDataFun();